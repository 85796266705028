
import { IonPage, IonContent, IonBackButton, IonToolbar } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import { Analytics } from '../../../common/analytics';


export default defineComponent({
  components: { IonPage, IonContent, IonBackButton, IonToolbar },
  setup () {
    const screenName = "PAGE-TERMS_PROVISION";
    const analytics = new Analytics;

    onMounted(() => {
        analytics.setScreenName(screenName);
      })
  }
})
